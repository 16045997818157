export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"name":"facebook-domain-verification","content":"tyg95e6cfhy9zvptbojg7yi039v1x3"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","type":"image/svg+xml","href":"/icon.svg"}],"style":[],"script":[{"key":"gtm-consent-script","hid":"gtm-consent-script","innerHTML":"\n      window.dataLayer = window.dataLayer || [];\n      function gtag(){dataLayer.push(arguments);}\n      gtag('consent', 'default', {\n        'ad_storage': 'denied',\n        'ad_user_data': 'denied',\n        'ad_personalization': 'denied',\n        'analytics_storage': 'granted',\n        'region': ['CA', 'CH', 'SG', 'AE', 'NL', 'JP', 'HK']\n      });\n      gtag('consent', 'default', {\n        'ad_storage': 'granted',\n        'ad_user_data': 'granted',\n        'ad_personalization': 'granted',\n        'analytics_storage': 'granted',\n        'region': ['MX', 'US', 'US-CA']\n      });\n      gtag('consent', 'default', {\n        'ad_storage': 'denied',\n        'ad_user_data': 'denied',\n        'ad_personalization': 'denied',\n        'analytics_storage': 'denied'\n      });\n    ","tagPriority":"high"},{"key":"one-trust-script","hid":"one-trust-script","type":"text/javascript","src":"//cdn.cookielaw.org/scripttemplates/otSDKStub.js","data-domain-script":"0192d86c-04a9-7967-9a48-20d2b66f78e8"},{"key":"one-trust-optanon-script","innerHTML":"function OptanonWrapper() { }","type":"text/javascript"}],"noscript":[],"title":"Adyen"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"