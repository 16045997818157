<template>
  <section class="ds-background-color-black ds-padding-bottom-48 ds-md-padding-bottom-96">
    <!-- title -->
    <div class="ds-container">
      <div class="ds-grid">
        <div class="ds-grid__col">
          <p class="ds-font-weight-bold ds-color-white ds-margin-bottom-12">
        {{ title }}
      </p>
          <DsDivider theme="dark" />
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="ds-padding-bottom-48 ds-padding-top-24 ds-lg-padding-top-48">
      <slot :is-open="isOpen" />
    </div>
    <!-- button -->
    <div class="ds-container ds-display-flex ds-flex-direction-column ds-align-items-center">
      <button
          type="button"
          class="ds-button-icon ds-button-icon--primary ds-background-color-grey-2500"
          @click="toggle"
          @keydown.esc="close"
      >
        <DsIconCore
            ref="plusIcon"
            name="plus"
            category="interface"
        />
      </button>
      <div class="ds-grid">
        <div class="ds-grid__col">
          <DsDivider
              class="collapsible-block-divider"
              theme="dark"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { DsDivider, DsIconCore } from 'awds'

const props = defineProps({
  title: {
    type: String,
    required: true
  }
})

const isOpen = ref(false)
const plusVerticalLine = ref()
const plusIcon = ref()

onMounted(() => {
  plusVerticalLine.value = plusIcon.value.$el.getElementsByTagName('path')[0]
  plusVerticalLine.value.style.transformOrigin = '50% 50%'
  plusVerticalLine.value.style.transition = 'all 500ms'
})

const open = () => {
  isOpen.value = true
  plusVerticalLine.value.style.transform = 'rotate(90deg)'
}

const close = () => {
  isOpen.value = false
  plusVerticalLine.value.style.transform = 'rotate(0deg)'
}

const toggle = () => {
  if (!isOpen.value) {
    open()
  } else {
    close()
  }
}

</script>

<style lang="scss" scoped>
@use 'sass:math';

@import 'awds/foundation-vars.scss';

$height-half: math.div($height-button, 2);

.collapsible-block-divider {
  margin-bottom: $height-half;
  margin-top: -$height-half;
}
</style>
