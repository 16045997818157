<template>
  <div :class="{ 'ds-background-color-black ds-color-white': theme === 'dark' }">
    <!-- Header: title -->
    <div
      v-if="title"
      :class="{ 'ds-container': useContainer }"
      class="ds-margin-bottom-0 ds-md-margin-bottom-48 ds-lg-margin-bottom-96"
    >
      <div class="ds-grid">
        <div class="ds-grid__col">
          <h2 class="ds-heading ds-md-heading-large">
            {{ title }}
          </h2>
        </div>
      </div>
    </div>
    <template v-if="collectionData">
      <!-- Body: financial blocks (large screen) -->
      <ClientOnly>
        <div v-if="isScreenLarge" :class="{ 'ds-container': useContainer }">
          <div class="ds-grid">
            <div
              v-for="(item, index) in collectionData"
              :key="`financial-block-${item.firstNumber.headline}`"
              class="ds-grid__col ds-grid__col--4"
            >
              <InvestorRelationsFinancialBlock
                :first-number="item.firstNumber"
                :second-number="item.secondNumber"
                :chart-data="item.chartData"
                :show-chart="showLineChart"
              />
            </div>
          </div>
        </div>
        <!-- Body: financial blocks (medium & small screen) -->
        <!-- Note: added extra 12 width for small screens to prevent labels overlap between graphs -->

        <DsImageGallery
          v-else
          :screen="screen"
          :items="collectionData.length"
          :width="{ small: 272 + 12, large: 368 }"
          type="secondary"
          :dark-mode="theme === 'dark'"
        >
          <template #default="{ index }">
            <InvestorRelationsFinancialBlock
              v-if="collectionData[index - 1]"
              :first-number="collectionData[index - 1].firstNumber"
              :second-number="collectionData[index - 1].secondNumber"
              :chart-data="collectionData[index - 1].chartData"
              :show-chart="showLineChart"
            />
          </template>
        </DsImageGallery>
      </ClientOnly>
    </template>

    <!-- Footer: usp & footnote -->
    <div v-if="uspData.length || footnote" class="ds-container">
      <div class="ds-grid">
        <div v-if="footnote" class="ds-grid__col ds-grid__col--12 ds-md-grid__col--7 ds-margin-top-48">
          <p class="ds-text-small ds-color-grey-450">
            {{ footnote }}
          </p>
        </div>
        <div
          v-if="uspData.length"
          class="ds-grid__col ds-grid__col--12 ds-margin-top-48 ds-md-margin-top-96 ds-margin-bottom-0 ds-md-margin-bottom-48"
        >
          <DsDivider theme="dark" />
        </div>
        <template v-if="uspData.length">
          <div
            v-for="(item, index) in uspData"
            :key="`usp-${index}`"
            class="ds-grid__col ds-grid__col--12 ds-md-grid__col--6 ds-margin-top-48"
          >
            <DsCardTextIcon :icon="item.icon" :title="item.title" with-title-size-large>
              <RichText v-if="item.description" :data="item.description" :class="{ 'ds-text': !item.title }" />
            </DsCardTextIcon>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DsCardTextIcon, DsDivider, DsImageGallery, DsScreenWrapper } from 'awds'
import InvestorRelationsFinancialBlock from './investor-relations-financial-block.vue'
const { screen, isScreenLarge } = useScreen()
const { labels } = useGlobalData()

const QUANTITY_BILLION = ref('Billion') // Quantity can be Billion or Million

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  year: {
    type: Number,
    default: new Date().getFullYear()
  },
  financialData: {
    type: Object,
    required: true
  },
  footnote: {
    type: String,
    default: ''
  },
  uspData: {
    type: Array,
    default: () => ([])
  },
  showLineChart: {
    type: Boolean,
    default: false
  },
  theme: {
    type: String,
    default: 'light'
  },
  useContainer: {
    type: Boolean,
    default: true
  }
})

const getArrowDirection = (items, key) => {
  const hasCurrentYearItems = items.filter(i => i.year === props.year).length > 0
  const currentYearItems = items.filter(i => i.year === (hasCurrentYearItems ? props.year : props.year - 1))
  const currentYearTotal = currentYearItems.reduce((total, i) => total + i[`${key}Yoy`], 0)
  return currentYearTotal >= 0 ? 'up' : 'down'
}

const collectionData = computed(() => {
  const halfYearElement = getLatestHalfYear(props.financialData?.halfYearData)
  // @TODO WEB-3425: review with Product: special case for h1-2023 SHL -> FY values should not show if there are no FY values for the current year
  // const _fullYearElement = this.getLatestFullYear(this.financialData.fullYearData, this.financialData.hasOnlyFullYearData)
  // const fullYearElement = (this.year === 2023 && _fullYearElement.year < this.year) ? null : _fullYearElement
  const fullYearElement = getLatestFullYear(props.financialData?.fullYearData, props.financialData?.hasOnlyFullYearData)

  if (halfYearElement) {
    return [
      // Net Revenue
      {
        firstNumber: {
          title: labels.value.netRevenue,
          period: `${halfYearElement.period} ${halfYearElement.year}`,
          yoy: halfYearElement.netRevenueYoy,
          headline: halfYearElement.netRevenueAmount,
          arrow: getArrowDirection(props.financialData.halfYearData, 'netRevenue'),
          isBillion: isBillion(halfYearElement.netRevenueQuantity)
        },
        ...(fullYearElement && {
          secondNumber: {
            period: `${fullYearElement.period} ${fullYearElement.year}`,
            yoy: fullYearElement.netRevenueYoy,
            headline: fullYearElement.netRevenueAmount,
            arrow: getArrowDirection(props.financialData.fullYearData, 'netRevenue'),
            isBillion: isBillion(fullYearElement.netRevenueQuantity)
          }
        }),
        chartData: props.financialData.chartData.netRevenue
      },
      // Processed Volume
      // @TODO keep processed volume data commented out because it can be requested to be render back anytime soon
      // {
      //   firstNumber: {
      //     title: labels.processedVolume,
      //     period: `${halfYearElement.period} ${halfYearElement.year}`,
      //     yoy: halfYearElement.processedVolumeYoy,
      //     headline: halfYearElement.processedVolumeAmount,
      //     arrow: getArrowDirection(props.financialData.halfYearData, 'processedVolume'),
      //     isBillion: isBillion(halfYearElement.processedVolumeQuantity)
      //   },
      //   ...(fullYearElement && {
      //     secondNumber: {
      //       period: `${fullYearElement.period} ${fullYearElement.year}`,
      //       yoy: fullYearElement.processedVolumeYoy,
      //       headline: fullYearElement.processedVolumeAmount,
      //       arrow: getArrowDirection(props.financialData.fullYearData, 'processedVolume'),
      //       isBillion: isBillion(fullYearElement.processedVolumeQuantity)
      //     }
      //   }),
      //   chartData: props.financialData.chartData.processedVolume
      // },
      // EBITDA
      {
        firstNumber: {
          title: labels.value.ebitda,
          period: `${halfYearElement.period} ${halfYearElement.year}`,
          yoy: halfYearElement.ebitdaYoy,
          headline: halfYearElement.ebitdaAmount,
          arrow: getArrowDirection(props.financialData.halfYearData, 'ebitda'),
          isBillion: isBillion(halfYearElement.ebitdaQuantity)
        },
        ...(fullYearElement && {
          secondNumber: {
            period: `${fullYearElement.period} ${fullYearElement.year}`,
            yoy: fullYearElement.ebitdaYoy,
            headline: fullYearElement.ebitdaAmount,
            arrow: getArrowDirection(props.financialData.fullYearData, 'ebitda'),
            isBillion: isBillion(fullYearElement.ebitdaQuantity)
          }
        }),
        chartData: props.financialData.chartData.ebitda
      }
    ]
  } else {
    return []
  }
})

const isBillion = (quantity) => {
  return quantity === QUANTITY_BILLION.value
}

const getLatestHalfYear = (financialData) => {
  const filteredDataCurrentYear = financialData.filter(el => el.year === props.year)
  const filteredData = filteredDataCurrentYear.length
    ? filteredDataCurrentYear
    : financialData.filter(el => el.year === props.year - 1)
  return filteredData[filteredData.length - 1]
}

const getLatestFullYear = (financialData, forceLastYear) => {
  const year = forceLastYear ? props.year - 1 : props.year
  return financialData.filter(el => el.year === year).find(el => el.period === 'FY') ??
    financialData.filter(el => el.year === year - 1).find(el => el.period === 'FY')
}

</script>
