<template>
  <div>
    <InvestorRelationsFinancialRecap
      :class="{ 'ds-margin-bottom-24': showSecondNumber }"
      :is-animated="!showChart && firstTime"
      v-bind="firstNumber"
    >
      <transition name="ds-expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
        <div v-if="showChart">
          <!-- Line chart -->
          <ClientOnly>
            <InvestorRelationsLineChart
              v-if="chartData && chartData.length >= 4"
              :chart-data="chartData"
              class="ds-margin-y-24"
            />
          </ClientOnly>
        </div>
      </transition>
    </InvestorRelationsFinancialRecap>
    <transition name="ds-expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
      <div v-if="showSecondNumber">
        <DsDivider theme="light" />
        <InvestorRelationsFinancialRecap
          class="ds-margin-top-24"
          v-bind="secondNumber"
          :is-animated="false"
          :with-headline-grey="true"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { DsDivider } from 'awds'
import InvestorRelationsFinancialRecap from './investor-relations-financial-recap.vue'
const InvestorRelationsLineChart = defineAsyncComponent(() => import('./investor-relations-line-chart.vue'))
const firstTime = ref(true)

const props = defineProps({
  firstNumber: {
    type: Object,
    required: true
  },
  secondNumber: {
    type: Object,
    default: null
  },
  chartData: {
    type: Array,
    required: true
  },
  showChart: {
    type: Boolean,
    required: true
  }
})

const { setExpandTransitionDuration, leave, enter, afterEnter } =
  useTransitionExpand()

const showSecondNumber = computed(() => {
  return (
    (props.showChart && props.secondNumber) ||
    (props.secondNumber && props.chartData.length < 4)
  )
})

onMounted(async () => {
  setExpandTransitionDuration('400ms')

  // We don't want the animation to trigger every time
  setTimeout(() => {
    firstTime.value = false
  }, 1000)
})
</script>
